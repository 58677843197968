<template>
    <div>
        <el-dialog
            title="订单退款"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="140px">
                <el-form-item label="退款金额" prop="refundAmount">
                    <jd-input-price v-model="form.refundAmount"></jd-input-price>
                </el-form-item>
                <el-form-item :label="'退款'+$Config.discountTypeDic[item.discountType]" prop="pointsAmount" v-for="(item,index) in form.refundDiscountVoList" :key="index">
                    <jd-input-price v-model="item.refundAmount"></jd-input-price>
                </el-form-item>
                <el-form-item label="安心充是否退款" prop="refundAnxin" v-if="superOrderItem">
                    <el-radio-group v-model="form.refundAnxin">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="优惠券是否退款" prop="refundAnxin" v-if="superOrderCoupon">
                    <el-radio-group v-model="form.refundAnxin">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="退款原因" prop="refundMsg">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        placeholder="请输入内容"
                        v-model="form.refundMsg">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <order-refound-retry ref="orderRefoundRetry"></order-refound-retry>
    </div>
</template>

<script>
    import orderRefoundRetry from './orderRefoundRetry'
    export default {
        components: {
            orderRefoundRetry
        },
        data() {
            var checkAmount = (rule, value, callback) => {
                if(this.originRefundAmount && !this.form.refundAmount){
                    callback(new Error('非全额优惠抵扣订单必须填写退款金额'))
                }else{
                    callback()
                }
            };
            return {
                dialogVisible: false,
                originRefundAmount: '',
                form: {
                    refundAmount: '',
                    refundDiscountVoList: [], //优惠信息
                    refundMsg: '',
                    refundAnxin: 0
                },
                id: '',
                orderType: '',//订单类型
                rules:{
                    refundAmount: [{ validator: checkAmount, trigger: 'blur' }],
                    refundMsg: [{required: true, message:'请输入退款原因', trigger: 'blur'}]
                },
                superOrderItem: '',
                superOrderCoupon: '',//是否合单购买了优惠券
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let checkResult = await this.checkRules(this.form.refundDiscountVoList)
                        if(!checkResult) {
                            return
                        }
                        let reqForm = {
                            ...this.form,
                            refundAmount: Number((this.form.refundAmount*100).toFixed(0)),
                        }
                        reqForm.refundDiscountVoList = reqForm.refundDiscountVoList.map(item=>{
                            return {
                                ...item,
                                refundAmount: Number((item.refundAmount*100).toFixed(0))
                            }
                        })
                        if(this.orderType == 2) {
                            this.chargeRefound(reqForm)
                        }else if(this.orderType == 4 || this.orderType == 5) {
                            this.cabinetRefound(reqForm)
                        }
                       
                    }
                })
            },
            cabinetRefound (reqForm) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.cabinetOrderRefund,
                    method: "post",
                    params: {
                        ...reqForm,
                        id:this.id,
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.dialogVisible = false
                        this.$emit('oprateFinish')
                    } else if(res.result.code == 1314) {
                        this.dialogVisible = false
                        this.$refs['orderRefoundRetry'].dialogVisible = true
                        this.$refs['orderRefoundRetry'].form = {
                            ...res.result.data.record
                        }
                        this.$message.error(res.result.message)
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    
                })
            },
            chargeRefound (reqForm) {
                this.$Axios._post({
					url: this.$Config.apiUrl.refundOrder,
					params: {
						...reqForm,
                        id:this.id,
					},
				}).then((res) => {
					if (res.result.code == 0) {
						this.$message.success('操作成功')
                        this.dialogVisible = false
                        this.$emit('oprateFinish')
					} else if(res.result.code == 1314) {
                        this.dialogVisible = false
                        this.$refs['orderRefoundRetry'].dialogVisible = true
                        this.$refs['orderRefoundRetry'].form = {
                            ...res.result.data.record
                        }
                        this.$message.error(res.result.message)
                    } else {
						this.$message.error(res.result.message)
					}
				}).catch((err) => {
                    
				})
            },
            checkRules (reqData) {
                return new Promise((resolve, reject) => {
                    if(reqData.length && reqData){
                        for (let index = 0; index < reqData.length; index++) {
                            const item = reqData[index];
                            reqData[index].refoundAll = Number(item.refundAmount) == item.originRefundAmount ? true : false // 当前优惠是否全额退款
                            if(Number(item.refundAmount) > item.originRefundAmount){
                                this.$message.error(this.$Config.discountTypeDic[item.discountType] + '的退款金额不得大于最大可退金额')
                                resolve(false)
                                return
                            }
                        }

                        let refoundMoneyAll = this.originRefundAmount == this.form.refundAmount ? true : false //现金是否全额退款
                        // 筛选是否存在优惠券
                        let couponDiscount = reqData.find(res=>{
                            return res.discountType == 5
                        })
                        if(couponDiscount) {
                            // 筛选是否存在没有全额退款(非优惠券)的优惠
                            let notRefoundDiscount = reqData.find(res=>{
                                return !res.refoundAll && res.discountType != 5
                            })

                            //当存在没有全额退款的优惠金额时，或者现金没有全额退款，则不允许退优惠券
                            
                            if((notRefoundDiscount || !refoundMoneyAll) && couponDiscount.refundAmount) {
                                this.$message.error('订单优惠券退款时，该笔订单必须全额退款')
                                resolve(false)
                                return
                            }

                            // 优惠券只能全额退款
                            if(couponDiscount.refundAmount && couponDiscount.refundAmount != couponDiscount.originRefundAmount) {
                                this.$message.error('订单优惠券退款只能全额退款或不退款')
                                resolve(false)
                                return
                            }
                        }
                        resolve(true)
                    }else{
                        resolve(true)
                    }
                })
            },
        }
    }
</script>