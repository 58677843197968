<template>
    <div>
        <el-dialog
            title="订单退款失败"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" label-position="left" label-width="140px">
                <el-form-item label="退款金额" prop="refundAmount">
                    <span>￥{{ util.numFormat(form.refundAmount) }}</span>
                </el-form-item>
                <el-form-item label="支付渠道" prop="refundAnxin">
                    <span v-if="form.orderType == 2">{{ form.payChannel | initDic(chargePayChannelDic) }}</span>
                    <span v-if="form.orderType == 1">{{ form.payChannel | initDic(packagePayChannelDic) }}</span>
                    <span v-if="form.orderType == 4 || form.orderType == 5">{{ form.payChannel | initDic(cabinetPayChannelDic) }}</span>
                </el-form-item>
                <el-form-item label="退款失败次数" prop="refundAmount">
                    <span>{{ form.failNum }}</span>
                </el-form-item>
                <el-form-item label="退款失败原因" prop="refundMsg">
                    <span>{{ form.failMessage }}</span>
                </el-form-item>
                <el-form-item label="退款状态" prop="refundMsg">
                    <span>{{ form.refundStatus | initDic(refundStatusDic) }}</span>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">重试</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import util from "../../../utils/util.js";
    export default {
        data() {
            return {
                util,
                dialogVisible: false,
                cabinetPayChannelDic: [],//充电柜支付渠道
                chargePayChannelDic: [],//充电桩支付渠道
                packagePayChannelDic: [],//套餐支付渠道
                refundStatusDic: [{
                    label: '未知',
                    value: 0
                },{
                    label: '处理中',
                    value: 1
                },{
                    label: '成功',
                    value: 2
                },{
                    label: '失败',
                    value: 3
                }],//
                form: {

                }
            };
        },
        mounted () {
            this.$getDic('payChannel').then(res=>{ this.cabinetPayChannelDic = res; })
            this.$getDic('turnoverPayChannel').then(res=>{ this.chargePayChannelDic = res; })
            this.$getDic('orderPayChannel').then(res=>{ this.packagePayChannelDic = res; })
            // this.$getDic('orderPayChannel').then(res=>{ this.refundStatusDic = res; })
        },
        methods:{
            submit () {
                this.$confirm('温馨提示','是否重新发起退款？')
                .then(_=>{
                    this.$Axios._get({
                        url: this.$Config.apiUrl.orderReFoundRetry,
                        params: {
                            retryId: this.form.id
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.dialogVisible = false
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{
                        
                    })
                })
                .catch(_=>{

                })
            }
        }
    }
</script>