<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">充电柜订单</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-under-select label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-date-range 
                    ref="dateRange" 
                    label="下单时间" 
                    valueFormat="yyyy-MM-dd HH:mm:ss"
                    :minDate.sync="pageParam.params.startTime" 
                    :maxDate.sync="pageParam.params.endTime" />
                <!-- <jd-select-input :value.sync="searchVal1.value" :selectValue.sync="searchVal1.select" :selectOptions="selectOptions1"></jd-select-input>
                <jd-select-input :value.sync="searchVal2.value" :selectValue.sync="searchVal2.select" :selectOptions="selectOptions2"></jd-select-input> -->
                <le-input label="订单编号" v-model="pageParam.params.orderSn" />
                <le-input label="用户手机" v-model="pageParam.params.phone" />
                <le-input label="设备号" v-model="pageParam.params.deviceCode" />
                <!-- <le-input label="站点名称" v-model="pageParam.params.stationName" /> -->
                <le-select-remote-search label="站点名称" v-model="stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
                <le-input label="站点ID" v-model="stationIdInput" />
                <le-input label="用户昵称" v-model="pageParam.params.nickName" />
                <le-select-local-search label="订单类型" v-model="pageParam.params.orderType" :options="orderTypeDic" />
            </le-search-form>
            <le-pagview ref="packageOrder" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.packageOrderList">
                <el-table 
                    ref="packageOrderList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    @filter-change='filterFun'
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    style="width: 100%">
                    <el-table-column prop="deviceCode" fixed="left" label="订单编号" min-width="180">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rsbc">
                                <el-image
                                    v-if="row.anxinService "
                                    style="width: 21px;height: 16px !important;margin-right: 5px;"
                                    fit='contain'
                                    src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/rest-charge-icon.png"></el-image>
                                <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="商户"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="收款商户"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.payeeCompanyName?row.payeeCompanyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="站点名称"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName?row.stationName:'-' }}</span>
                        </template>
                    </el-table-column>    
                    <el-table-column 
                        prop="deviceCode" 
                        label="设备号"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceCode?row.deviceCode:'-' }}-{{ row.boxId }}</span>
                        </template>
                    </el-table-column>    
                    <el-table-column 
                        prop="name" 
                        label="订单类型"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                    </el-table-column>                 
                    <el-table-column 
                        prop="name" 
                        label="用户手机"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.phone?row.phone:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="订单金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="实际支付"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠支付" min-width="120">
						<template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start">
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.discountPayAmount) }}
                                </span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column label="退款" min-width="120">
						<template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span>现金：￥{{ util.numFormat(row.refundAmount) }}</span>
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.refundPayAmount) }}
                                </span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column label="安心充支付金额" min-width="120">
						<template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start" v-if="row.restCharge">
                                <span>现金：￥{{ util.numFormat(row.restCharge.payAmount) }}</span>
							    <span v-for="(item,index) in row.restCharge.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.discountPayAmount) }}
                                </span>
                            </div>
                            <div v-else>-</div>
						</template>
					</el-table-column>
                    <el-table-column label="安心充退款" min-width="120">
						<template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start" v-if="row.restCharge">
                                <span>现金：￥{{ util.numFormat(row.restCharge.refundAmount) }}</span>
							    <span v-for="(item,index) in row.restCharge.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.refundPayAmount) }}
                                </span>
                            </div>
                            <div v-else>-</div>
						</template>
					</el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="开柜时间"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderStartTime?row.orderStartTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="取电时间"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderEndTime?row.orderEndTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="充电开始时间"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargingStartTime?row.chargingStartTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="充电结束时间"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargingEndTime?row.chargingEndTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column 
                        prop="name" 
                        label="支付渠道"
                        column-key="payChannel"
                        :filter-multiple='false'
                        :filters="payChannelDic"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        :label="orderStatus | initTablelabel(orderStatusDic,'订单状态')" 
                        column-key="orderStatus"
                        :filter-multiple='false'
                        :filters="orderStatusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderStatus | initDic(orderStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        :label="pageParam.params.payStatus | initTablelabel(payStatusDic,'支付状态')" 
                        column-key="payStatus"
                        :filter-multiple='false'
                        :filters="payStatusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="退款原因" 
                        min-width="170">
                        <template slot-scope="{ row }"> 
                            <span>{{ row.refundMsg?row.refundMsg:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        :label="pageParam.params.orderGoodsType | initTablelabel(goodsTypeDic,'商品类型')" 
                        column-key="goodsType"
                        :filter-multiple='false'
                        :filters="goodsTypeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderGoodsType | initDic(goodsTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="90" fixed="right" >
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="订单详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toMoreInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="退款" placement="top" v-if="userInfo.dataPermission == 99 || company.companyType == 2">
                                <img src="../../assets/icon/option-refund.png" class="a-wh-16" 
                                    v-if="scope.row.payStatus == 1 || scope.row.payStatus == 2 || scope.row.payStatus == 4" 
                                    @click="refound(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="结束订单" placement="top" v-if="userInfo.dataPermission == 99 || company.companyType == 2">
                                <img src="../../assets/icon/option-refuse.png" class="a-wh-16" v-if="scope.row.orderStatus == 1" @click="closeOrder(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="手动扣款" placement="top" v-if="scope.row.orderStatus == 8">
                                <img @click="managePay(scope.row)" src="../../assets/icon/option-deduction.png" class="a-wh-16" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <cabinet-order-refund ref="cabinetOrderRefund" @oprateFinish="refund"></cabinet-order-refund>
    </div>
</template>

<script>
import util from '../../utils/util'
import CabinetOrderRefund from './child/cabinetOrderRefund.vue'
import { mapState } from 'vuex';
let _this
export default {
    components:{
        CabinetOrderRefund
    },
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.cabinetOrderPage,
                method: "post",
                params: {
                    companyId: '',
                    phone: '',
                    nickName: '',
                    orderSn: '',
                    goodsId: '',
                    goodsName: '',
                    stationId: '',
                    stationName: '',
                    startTime: '',
                    endTime: '',
                    orderStatus: [],
                    payStatus: '',
                    orderGoodsType: '',
                    payChannel: '',
                    deviceCode: '',
                    orderType: ''
                },
                freshCtrl: 1,
            },
            stationId: '',
            stationIdInput: '',
            searchVal1: {
                value: '',
                select: '1'
            },
            selectOptions1: [{
                label: '用户手机',
                value: '1'
            },{
                label: '用户昵称',
                value: '2'
            },{
                label: '订单编号',
                value: '3'
            },{
                label: '设备号',
                value: '4'
            }],
            searchVal2: {
                value: '',
                select: '1'
            },
            selectOptions2: [{
                label: '站点名称',
                value: '1'
            },{
                label: '站点ID',
                value: '2'
            }],
            payChannelDic: [],//支付渠道字典
            orderStatusDic: [],//订单状态字典
            payStatusDic: [],//支付状态字典
            goodsTypeDic: [],//商品订单类型字典
            orderTypeDic: [{
                label: '充电柜订单',
                value: '4'
            },{
                label: '充电舱订单',
                value: '5'
            }],//订单类型
            orderStatus: '',
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
                pageSize: 100
            },//站点数据
        };
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        this.$getDic('boxOrderStatus').then(res=>{ this.orderStatusDic = res; })
        this.$getDic('payStatus').then(res=>{ this.payStatusDic = res; })
        this.$getDic('goodsType').then(res=>{ this.goodsTypeDic = res; })
    },
    activated () {
		this.pageParam.freshCtrl++;
	},
    watch: {
        // searchVal1:{
        //     deep: true,
        //     immediate:true,
        //     handler (val) {
        //         this.pageParam.params.phone = val.select==1?val.value:''
        //         this.pageParam.params.nickName = val.select==2?val.value:''
        //         this.pageParam.params.orderSn = val.select==3?val.value:''
        //         this.pageParam.params.deviceCode = val.select==4?val.value:''
        //     }     
        // },
        // searchVal2:{
        //     deep: true,
        //     immediate:true,
        //     handler (val) {
        //         this.pageParam.params.stationName = val.select==1?val.value:''
        //         this.pageParam.params.stationId = val.select==2?val.value:''
        //     }     
        // }
    },
    methods: {
        setTableData(data) {
            // this.tableData = data;
            this.tableData = data.map(item=>{
                let refoundAmountAllPoint = 0
                let payAmountAllPoint = 0
                if(item.orderDiscountInfoList && item.orderDiscountInfoList.length) {
                    item.orderDiscountInfoList.map(ite=>{
                        if(ite.discountType == 4) {
                            refoundAmountAllPoint += Number(ite.refundPayAmount)
                            payAmountAllPoint += Number(ite.discountPayAmount)
                        }
                    })
                }

                let restCharge
                if(item.superOrderItem) {
                    restCharge = item.superOrderItem.find(res=>{
                        return res.goodsType == 2
                    })
                }
                
                return {
                    ...item,
                    refoundAmountAllPoint: refoundAmountAllPoint,
                    payAmountAllPoint: payAmountAllPoint,
                    restCharge: restCharge
                }
            });
        },
        handlerRest() {
            // this.searchVal1 = {
            //     value: '',
            //     select: '1'
            // }
            // this.searchVal2 = {
            //     value: '',
            //     select: '1'
            // }
            this.pageParam.params = {
                companyId: '',
                phone: '',
                nickName: '',
                orderSn: '',
                goodsId: '',
                goodsName: '',
                stationId: '',
                stationName: '',
                startTime: '',
                endTime: '',
                orderStatus: [],
                payStatus: '',
                orderGoodsType: '',
                payChannel: '',
                deviceCode: '',
                orderType: ''
            };
            this.orderStatus = ''
            this.stationId = ''
            this.stationIdInput = ''
            this.$refs['packageOrderList'].clearFilter()
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['packageOrder'].pageNum = 1
            this.pageParam.params.orderStatus = this.orderStatus?[this.orderStatus]:[]
            this.pageParam.params.orderStatus = this.orderStatus?[this.orderStatus]:[]
            this.pageParam.params.stationId = this.stationIdInput || this.stationId
            this.pageParam.freshCtrl++;
        },
        // 订单退款
        async refound (row) {
            let datas = await this.getCabinetOrderInfo(row)
            this.$refs['cabinetOrderRefund'].id = datas.id
            this.$refs['cabinetOrderRefund'].orderType = datas.orderType //订单类型
            this.$refs['cabinetOrderRefund'].originRefundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
            this.$refs['cabinetOrderRefund'].form.refundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
            this.$refs['cabinetOrderRefund'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6//退款时将vip优惠剔除出去
            }).map(item=>{
                return {
                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                    discountType: item.discountType
                }
            })
            if(datas.superOrderItem) {
                this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem.find(res=>{
                    return res.goodsType == 2
                }) //安心充订单
            }
            this.$refs['cabinetOrderRefund'].dialogVisible = true
        },
        // 查看充电柜订单详情
        getCabinetOrderInfo (datas) {
            return new Promise((resolve, reject) => {
                this.$Axios._get({
                    url: this.$Config.apiUrl.cabinetOrderInfo,
                    params: {
                        boxOrderId: datas.orderSn
                    },
                }).then((res) => {
                    if(res.result.code == 0){
                        resolve(res.result.data)
                    }else{
                        this.$message.error(res.result.message)
                        reject()
                    }
                }).catch(err=>{
                    this.$message.error(err.result.message)
                    reject()
                })
            })
        },
        refund () {
            this.pageParam.freshCtrl++;
        },
        //条件筛选
        filterFun(value){
            for(var key in value){
                if(key=='orderStatus'){
                    if(value[key].length==0){
                        this.orderStatus = ''
                    }else{
                        this.orderStatus = value[key][0]
                    }
                }
                if(key=='payStatus'){
                    if(value[key].length==0){
                        this.pageParam.params.payStatus = ''
                    }else{
                        this.pageParam.params.payStatus = value[key][0]
                    }
                }
                if(key=='goodsType'){
                    if(value[key].length==0){
                        this.pageParam.params.orderGoodsType = ''
                    }else{
                        this.pageParam.params.orderGoodsType = value[key][0]
                    }
                }
                if(key=='payChannel'){
                    if(value[key].length==0){
                        this.pageParam.params.payChannel = ''
                    }else{
                        this.pageParam.params.payChannel = value[key][0]
                    }
                }
            }
            this.handlerSearch()
            this.pageParam.freshCtrl++;
        },
        // 订单信息
        toMoreInfo (datas) {
            this.$router.push({
                path:'/order/chargeCabinet-order-info',
                query: {
                    orderId: datas.id,
                    orderSn: datas.orderSn
                }
            })
        },
        closeOrder (datas) {
            this.$confirm('是否确定结束订单?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$Axios._post({
					url: this.$Config.apiUrl.cabinetCloserOrder,
                    method: "post",
					params: {
						orderId: datas.id
					},
				}).then((res) => {
					if (res.result.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						})
                        this.pageParam.freshCtrl++;
					}
				}).catch((res) => {
					this.$message({
						type: 'error',
						message: '操作失败' + res.result.msg
					})
				})
			}).catch(() => {

			});
        },
        // 管理员手动扣款
        managePay (datas) {
            this.$confirm('是否确定手动扣款?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$Axios._post({
					url: this.$Config.apiUrl.orderRepay,
                    method: "post",
					params: {
						orderType: datas.orderType,
                        orderSn: datas.orderSn
					},
				}).then((res) => {
					if (res.result.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						})
                        this.pageParam.freshCtrl++;
					}
				}).catch((res) => {
					this.$message({
						type: 'error',
						message: '操作失败' + res.result.msg
					})
				})
			}).catch(() => {

			});
        },
        exportfile () {
            if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).length > 92){
                this.$message.error('最大导出范围三个月')
                return
            }
            let startDate = this.pageParam.params.startTime || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
            let endDate = this.pageParam.params.endTime || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
            this.$exfile({
                code: 14,
                fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 充电柜订单',
                startTime: startDate.split(' ')[0],
                endTime: endDate.split(' ')[0],
                params: {
                    ...this.pageParam.params,
                    startDate: startDate,
                    endDate: endDate
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 75px !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>
